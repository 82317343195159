import classNames from "classnames";
import { Nav, NavLink } from "reactstrap";
import { StepWizardChildProps } from "react-step-wizard";
import "./StepWizardNav.scss";

interface IStepWizardNav extends Partial<StepWizardChildProps> {
  pendingCompletedSteps: number[];
  stepNames: string[];
}

const StepWizardNav = (props: IStepWizardNav) => {
  const steps: JSX.Element[] = [];

  for (let i = 1; i <= props.totalSteps!; i++) {
    const isCurrentStepCompleted = checkIfStepIsCompleted(i);
    const isPreviousStepCompleted = checkIfStepIsCompleted(i - 1);
    const isCurrentStepActive = props.currentStep === i;

    const isClickAllowed =
      isCurrentStepActive || isCurrentStepCompleted || isPreviousStepCompleted;

    // Completed steps have a checkmark instead of a number for their step numbers
    const stepNumberDisplay = isCurrentStepCompleted ? (
      <i className="fa fa-solid fa-check" />
    ) : (
      i
    );

    const stepClasses = classNames({
      "active-step": isCurrentStepActive,
      "completed-step": isCurrentStepCompleted,
      "non-completed-step": !isCurrentStepCompleted,
    });

    steps.push(
      <NavLink
        className={`modal-step pb-2 ${stepClasses}`}
        disabled={!isClickAllowed}
        key={`step-${i}`}
        onClick={() => (isClickAllowed ? props.goToStep!(i) : null)}
      >
        <span
          className={`step-number ${stepClasses}`}
          data-testid={`step-number`}
        >
          {stepNumberDisplay}
        </span>
        <span
          className={`step-label ${stepClasses}`}
          data-testid={`step-label`}
        >
          {props.stepNames[i - 1]}
        </span>
      </NavLink>,
    );
  }

  function checkIfStepIsCompleted(stepNumber: number): boolean {
    return props.pendingCompletedSteps.includes(stepNumber);
  }

  return <Nav className="step-wizard-nav mt-3">{steps}</Nav>;
};

export default StepWizardNav;
